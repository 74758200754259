import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="Σχετικά" />
    <h2>Σχετικά</h2>
    <p>Η ιστοσελίδα αυτή παρουσιάζει φωτογραφίες από μαλάκες οδηγούς που οδηγούν ή παρκάρουν σαν μαλάκες.</p>
    <hr />
    <h3>Επικοινωνία:</h3>
    <form name="contact" method="post" action="/about/success" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label>
          Όνομα:
          <br />
          <input type="text" name="name" size="40" required />
        </label>
      </p>
      <p>
        <label>
          Email:
          <br />
          <input type="text" name="email" size="40" placeholder="προαιρετικό" />
        </label>
      </p>
      <p>
        <label>
          Μήνυμα:
          <br />
          <textarea type="text" name="text" required />
        </label>
      </p>
      <p>
        <input type="submit" value="Αποστολή" />
      </p>
    </form>
  </Layout>
)

export default AboutPage
